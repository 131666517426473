import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCalendar,
  cilFile,
  cilPeople,
  cilLayers,
  cilTv,
  cilImage,
  cilSpeedometer,
  cilUser, cilHouse,
} from '@coreui/icons'
import { CNavItem } from '@coreui/react-pro'

import { t } from "i18next";


export default function Nav() {

  const translate = t;

  const [ currentView ] = useState(
    localStorage.getItem("currentView") !== null && localStorage.getItem("currentView") !== undefined ?
      localStorage.getItem("currentView") : "guest"
  );
  

  // dashboard , users , persons , boxes , spaces , contents , campaigns , scheduling , logs
  const roles_permission={
    "admin" : {
      "administrator" : [1,1,1,1,1,1,1,1,1],
      "userAdmin" : [1,1,1,0,0,0,0,0,1],
      "boxAdmin" : [1,0,0,1,1,0,0,0,1],
      "campaignAdmin" : [1,0,0,0,0,0,1,0,1],
      "contentAdmin" : [1,0,0,0,0,1,0,0,1],
      "schedulingAdmin" : [1,0,0,0,0,0,0,1,1]
    },
    
    "guest" : {
      "guest" : [1,0,0,0,0,0,0,0,0]
    },

    "user" : {
      "user" : [1,0,0,0,0,0,0,0,1]
    },
    
    "owner" : {
      "owner" : [1,0,0,1,1,0,0,0,1]
    },
    
    "client" : {
      "client" : [1,0,0,0,0,1,1,0,1]
    }
  }

  
  const current_roles_permission = roles_permission[currentView];


  const user_roles = JSON.parse(localStorage.getItem("roles"));
  // this is needed because if a user has multiple roles then we need to check all the pages that he has access to
  let allowed_indexs_final = [1,0,0,0,0,0,0,0,1]; // 9 pages -> 9 elements
  for (let i in user_roles){
    let currentRole = user_roles[i];

    //checks if role is in roles_permission dict of the current view
    if (current_roles_permission[currentRole] !== undefined){
      for (let j in allowed_indexs_final) {
        if (current_roles_permission[currentRole][j] === 1) {
          allowed_indexs_final[j] = 1;
        }
      }
    }
  }

  let dashboardItem = {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    disabled: allowed_indexs_final[0]===0,
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  }

  let usersItem = {
    component: CNavItem,
    name: translate('nav_bar.users'),
    to: '/users',
    disabled: allowed_indexs_final[1]===0,
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let personsItem = {
    component: CNavItem,
    name: translate('nav_bar.persons'),
    to: '/persons',
    disabled: allowed_indexs_final[2]===0,
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let boxesItem = {
    component: CNavItem,
    name: 'Boxes',
    to: '/boxes',
    disabled: allowed_indexs_final[3]===0,
    icon: <CIcon icon={cilTv} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let spacesItem = {
    component: CNavItem,
    name: translate('space.spaces'),
    to: '/spaces',
    disabled: allowed_indexs_final[4]===0,
    icon: <CIcon icon={cilHouse} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let contentsItem = {
    component: CNavItem,
    name: translate('nav_bar.contents'),
    to: '/contents',
    disabled: allowed_indexs_final[5]===0,
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let campaignsItem = {
    component: CNavItem,
    name: translate('nav_bar.campaigns'),
    to: '/campaigns',
    disabled: allowed_indexs_final[6]===0,
    icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let schedulingsItem = {
    component: CNavItem,
    name: translate('nav_bar.scheduling'),
    to: '/scheduling',
    disabled: allowed_indexs_final[7]===0,
    icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }

  let logsItem = {
    component: CNavItem,
    name: 'Logs',
    to: '/logs',
    disabled: allowed_indexs_final[8]===0,
    icon: <CIcon icon={cilFile} customClassName="nav-icon" />,
    badge: {
      color: 'info-gradient',
    },
  }




  const _nav_array = {
    "guest" : [
      dashboardItem
    ],
    
    "user" : [
      dashboardItem,
      logsItem
    ],
    
    "owner" : [
      dashboardItem,
      boxesItem,
      spacesItem,
      logsItem
    ],
    
    "client" : [
      dashboardItem,
      contentsItem,
      campaignsItem,
      logsItem
    ],
    
    "admin" : [
      dashboardItem,
      usersItem,
      personsItem,
      boxesItem,
      spacesItem,
      contentsItem,
      campaignsItem,
      schedulingsItem,
      logsItem
    ]
  }

  const _nav = _nav_array[currentView];



  
  return { _nav }
}
