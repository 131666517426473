import React,{ useContext } from 'react'
import { Context } from '../../context/AuthContext';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser, cilCheckCircle, cilWarning, cilBurn } from '@coreui/icons'
import {CAlert} from "@coreui/react";
import { useTranslation } from 'react-i18next';

function Login (){
  const { t } = useTranslation();
  const translate = t;

  const { handleLogin , alertsList} = useContext(Context);

  const handleSubmit = (event) => {
    
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else{
      event.preventDefault();
      const data = new FormData(event.target);
      const values = {
        email: data.get('usernameInput'),
        password: data.get('password_user'),
      };
      handleLogin(values);
    }
  };
  
  return (
    <>
      <div className="bg-light">
        <CContainer>
        {alertsList
          .filter(alert => alert.visible)
          .map(visibleAlert => {
            const i = alertsList.indexOf(visibleAlert);
            return (
              
              <CAlert
                id={"log_number_" + i}
                key={i.toString()}
                color={visibleAlert.color}
                dismissible
                visible={visibleAlert.visible}
                onClose={() => {visibleAlert.visible = false;}}
              >
                
                {visibleAlert !== null ?
                  visibleAlert.color === "success" ?
                    <CIcon icon={cilCheckCircle} className="flex-shrink-0 me-2" width={24} height={24} />
                    : (visibleAlert.color ===  "warning" ?
                      <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                      : (visibleAlert.color ===  "danger" ?
                        <CIcon icon={cilBurn} className="flex-shrink-0 me-2" width={24} height={24} />
                        : null
                      )
                    )
                  : null
                }
                {visibleAlert.message}
              </CAlert>
            )
          })
        }
        </CContainer>
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={6}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>


                      <CForm className="row g-3 needs-validation" onSubmit={handleSubmit}>
                        <h1>{translate('login.login')}</h1>
                        <p className="text-medium-emphasis">{translate('login.sign_in')}</p>


                        <CInputGroup className="has-validation mb-3" >
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Username"
                            autoComplete="username"
                            type="text"
                            id="validationTooltipUsername"
                            aria-describedby="inputGroupPrepend"
                            required
                            name="usernameInput"/>
                        </CInputGroup>



                        <CInputGroup className="mb-4">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Password"
                            autoComplete="current-password"
                            type="password"
                            id="validationTooltipPassword"
                            //aria-describedby="inputGroupPrepend"
                            required
                            name ="password_user"
                          />
                        </CInputGroup>


                            <CButton color="primary" className="px-4" type="submit">
                              {translate('login.login')}
                            </CButton>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </>
  )
}export default Login;
