import React, { useContext} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Context } from './context/AuthContext';
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardImage,
  CCardBody,
  CCardTitle,
  CCardText
  
} from '@coreui/react-pro';

import DefaultLayout from'./layout/DefaultLayout'
import Login from './views/login/Login'
import Page404 from './views/page404/Page404'
import Page500 from './views/page500/Page500'

import logoFullLight from './assets/images/wexcedo_logo_full_light_mode.png'

import { t } from "i18next";


export function CustomRoute({ isPrivate, ...rest }) {

  const translate = t;
  const { loading, authenticated, deleteInfoInLocalStorage } = useContext(Context);

  if (loading) {
    return (
      <>
        <CContainer fluid>
          <CRow className='mt-4 justify-content-md-center'>
            <CCol className='mt-4 row justify-content-md-center'>
              <CCard className="w-50">
                <CCardImage orientation="top" src={logoFullLight} />
                <CCardBody>
                  <CCardTitle>{translate('dashboard.loading')}</CCardTitle>
                  <CCardText>
                    {translate('dashboard.loading_message')}
                  </CCardText>
                  {/*<CButton href="#">Go somewhere</CButton>*/}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </>);
  }

  if (isPrivate && !authenticated) {
    deleteInfoInLocalStorage();
    return <Redirect to="/login" />
  }
  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute  path="/login"  component={Login}/>
      <CustomRoute  exact path="/404" component={Page404}/>
      <CustomRoute  exact path="/500"  component={Page500}/>
      <CustomRoute  isPrivate path="/" component={DefaultLayout} />


      {/* <CustomRoute path="/sign-up" exact component={SignUp} />
            <CustomRoute path="/sign-in" exact component={SignIn} />   */}

      {/* Any invalid URL will get redirected to the dashboard after validating if the user is authenticated.
                <CustomRoute isPrivate exact path="/*"><Redirect to="/dashboard" /> </CustomRoute>
                <CustomRoute isPrivate exact path="/persons" component={Persons} />
                <CustomRoute isPrivate exact path="/dashboard" component={Home} />
                <CustomRoute isPrivate exact path="/users" component={Users} />
                <CustomRoute isPrivate exact path="/tables" component={Tables} />
                <CustomRoute isPrivate exact path="/billing" component={Billing} />
                <CustomRoute isPrivate exact path="/profile" component={Profile} />*/}
    </Switch>
  );
}
