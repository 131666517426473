import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const ListPersons = React.lazy(() => import('./views/persons/ListPersons'))
const ListSchedulings = React.lazy(() => import('./views/schedulingList/ListSchedulings'))
const ListSchedulingDetails = React.lazy(() => import('./views/schedulingList/ListSchedulingsDetails'))
const Schedule = React.lazy(() => import('./views/schedulingList/Schedule'))

const CampaignScheduling = React.lazy(() => import('./views/scheduling/Scheduling'))
const CampaignListSchedulings = React.lazy(() => import('./views/scheduling/ListSchedulings'))
const CreateCampaignScheduling = React.lazy(() => import('./views/scheduling/CreateCampaignScheduling'))
const ListSchedulingsDetails = React.lazy(() => import('./views/scheduling/ListSchedulingsDetails'))
const Contents = React.lazy(() => import('./views/contents/Contents'))
const ListContents = React.lazy(() => import('./views/contents/ListContents'))
const Boxes = React.lazy(() => import('./views/boxes/Boxes'))
const Spaces = React.lazy(() => import('./views/spaces/Spaces'))
const Logs = React.lazy(() => import('./views/logs/Logs'))
const ListCampaigns = React.lazy(() => import('./views/campaigns/ListCampaigns'))
const ListCampaignDetails = React.lazy(() => import('./views/campaigns/ListCampaignDetails'))
const ListUsers = React.lazy(() => import('./views/users/ListUsers'))
const Alerts = React.lazy(() => import('./views/alerts/Alerts'))
const CheckSchedulings = React.lazy(() => import('./views/scheduling/CheckSchedulings'))
const Profile = React.lazy(() => import('./views/profile/Profile'))


const routes_breadcrumbs = [
  { path: '/', exact: true, name: 'Dashboard' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/persons', name: 'Persons', component: ListPersons, exact: true},
  { path: '/scheduling', name: 'Scheduling', component: ListSchedulings, exact: true },
  { path: '/scheduling-details', name: 'Details', component: ListSchedulingDetails, exact: true },
  { path: '/scheduling-schedule', name: 'Scheduling', component: Schedule, exact: true },

  { path: '/scheduling-campaigns', name: 'Scheduling', component: CampaignScheduling, exact: true },
  { path: '/scheduling-campaigns-details', name: 'Details', component: CampaignListSchedulings, exact: true },
  { path: '/scheduling-campaigns-details-create', name: 'Create', component: CreateCampaignScheduling, exact: true },
  { path: '/scheduling-campaigns-details-schedule', name: 'Edit', component: ListSchedulingsDetails, exact: true },
  { path: '/campaigns', name: 'Campaigns', component: ListCampaigns, exact: true },
  { path: '/campaigns-details', name: 'Details', component: ListCampaignDetails, exact: true },
  { path: '/contents-create', name: 'Create', component: Contents },
  { path: '/contents', name: 'Contents', component: ListContents, exact:true },
  { path: '/users', name: 'Users', component: ListUsers, exact:true },
  { path: '/boxes', name: 'Boxes', component: Boxes, exact: true},
  { path: '/spaces', name: 'Spaces', component: Spaces },
  { path: '/logs', name: 'Logs', component: Logs },
  { path: '/alerts', name: 'Alerts', component: Alerts },
  { path: '/check-schedulings', name: 'Check Schedulings', component: CheckSchedulings },
  { path: '/profile', name: 'Profile', component: Profile },
];

export default routes_breadcrumbs
