import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import { format as formatDate, isDate} from "date-fns";
import { enGB, pt } from "date-fns/locale"; // import all locales we need
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';

const locales = { enGB, pt }; // used to look up the required locale

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        interpolation: {
        escapeValue: false, // react already safes from xss

        format: (value, format, lng) => {
          if (isDate(value)) {
              const locale = locales[lng];
              //Need to follow date fns format
              if (format === "short")
                  return formatDate(value, "P", { locale });
              if (format === "long")
                  return formatDate(value, "PPPP", { locale });
              if (format === "complete")
                  return formatDate(value, "Pp", { locale });
      
              return formatDate(value, format, { locale });
          }
      
          return value;
        }
        }
      }
    );

export default i18n;