import React from 'react'
import { CFooter } from '@coreui/react-pro'

const AppFooter = () => {
  return (
    <CFooter>
      <div className="ms-auto" >
        <span className="me-1">Powered by &copy;</span>
        <a href="https://wexcedo.com/" target="_blank" rel="noopener noreferrer">
          wexcedo
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
