import React, {Suspense, useContext, useEffect} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import {
  cilCheckCircle,
  cilWarning,
  cilBurn
} from '@coreui/icons'
import {
  CContainer,
  CSpinner,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton
} from '@coreui/react-pro'

import {Context} from "../context/AuthContext";
import {CAlert} from "@coreui/react";
// Routes_breadcrumbs config
import routes_breadcrumbs from "../Routes_breadcrumbs";


const AppContent = () => {

  const {alertsList, handleLogout, showingSessionExperiedModal, setShowingSessionExperiedModal} = useContext(Context);

  useEffect(() => {},[alertsList])

  return (
    <>
      <CModal visible={showingSessionExperiedModal} onClose={() => {setShowingSessionExperiedModal(false); handleLogout()}}>
        <CModalHeader onClose={() => {setShowingSessionExperiedModal(false); handleLogout()}}>
          <CModalTitle>Session Expired</CModalTitle>
        </CModalHeader>
        <CModalBody>Session Expired. Going to Login Screen</CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={() => {setShowingSessionExperiedModal(false); handleLogout()}}>
            Go to Login
          </CButton>
        </CModalFooter>
      </CModal>
      <div>
        {alertsList
          .filter(alert => alert.visible)
          .map(visibleAlert => {
            const i = alertsList.indexOf(visibleAlert);
            return (
              
              <CAlert
                id={"log_number_" + i}
                key={i.toString()}
                color={visibleAlert.color}
                dismissible
                visible={visibleAlert.visible}
                onClose={() => {visibleAlert.visible = false;}}
              >
                {visibleAlert !== null ?
                  visibleAlert.color === "success" ?
                    <CIcon icon={cilCheckCircle} className="flex-shrink-0 me-2" width={24} height={24} />
                    : (visibleAlert.color ===  "warning" ?
                      <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                      : (visibleAlert.color ===  "danger" ?
                        <CIcon icon={cilBurn} className="flex-shrink-0 me-2" width={24} height={24} />
                        : null
                      )
                    )
                  : null
                }
                {visibleAlert.message}
              </CAlert>
            )
          })
        }
      </div>

      <CContainer lg>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Switch>
            {
              routes_breadcrumbs.map((route, idx) => {
              return (
                route.component && (
                  <Route isPrivate key={idx} path={route.path} exact={route.exact}
                         name={route.name} render={(props) => (<><route.component {...props} /></>)}
                  />
                )
              )
            })
            }
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </CContainer>
    </>
  )
}
export default React.memo(AppContent)
