import React, { useContext, useEffect, useState } from 'react'
import {Context} from "../context/AuthContext"

import { useSelector, useDispatch } from 'react-redux'
import {
  CButtonGroup,
  CFormCheck,
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CButton,
  CBadge
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  cilMenu,
  cilMoon,
  cilSun,
  cilBell,
  cilCalendar
} from '@coreui/icons'
import history from '../history'

import { AppBreadcrumb } from './index'

import { t } from "i18next";

import {
  AppHeaderDropdown
} from './header/index'


import { useTranslation } from 'react-i18next';
import API_Calls_Generic from '../utils/api/API_Calls_Generic'
import API_Calls_Users from '../utils/api/API_Calls_Users'

const AppHeader = () => {

  const translate = t;
  
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme)

  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const sidebarShow = useSelector((state) => state.sidebarShow)

  const { i18n } = useTranslation();

  const possibleViews = localStorage.getItem("possibleViews").split(",");

  const [ currentView, setCurrentView ] = useState(localStorage.getItem("currentView"));

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const changeView = e => {
    e.target.parentNode.parentNode.parentNode.parentNode.click();
    setCurrentView(e.target.innerText);

    localStorage.setItem("currentView", e.target.innerText);

    history.push("/");
    window.location.reload();
  };


  // TODO: put some number of new notifications, get a new endpoint
  
  const [listLogs , setListLogs] = useState(null);

  //Get token from context
  const token = localStorage.getItem('token');
  //Get id from context
  const id = localStorage.getItem('id');

  const { isAuthenticated, setShowingSessionExperiedModal, handleLogout, addAlert } = useContext(Context);

  //used to disable order by option if none sort is selected. Also saves the values of sort and order
  const sortDefined = {sortOption:"none", order:""};

  const page_size = 20;
  //Page size
  const filters = {
    page_size: page_size,
    page: 1,
    start_date: "",
    end_date: ""
  }

  function getListAlerts (listOfFilters){
    
    let jsonParameters =
      API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).filtersAndSortsToJsonParameters(listOfFilters, sortDefined);


    API_Calls_Users(setShowingSessionExperiedModal, handleLogout, addAlert).getUserAlerts(
      token,
      jsonParameters,
      id,
      "",
      "",
      setListLogs,
      "Getting the alerts to display",
      "Alerts"
    )
  }
  
  useEffect(() => {
    isAuthenticated();
    getListAlerts(filters);
  },[isAuthenticated]);


  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        {
          /*
          <CHeaderBrand className="mx-auto d-md-none" to="/">
            <CImage fluid src={logo}/>
          </CHeaderBrand>
          */
        }

        <AppBreadcrumb/>

        <CHeaderNav className="ms-auto me-4" >
          {
            possibleViews === null || possibleViews === undefined || possibleViews.length === 0 ?
            "Something went wrong with the loading, please logout and login again"
            : possibleViews.length === 1 ?
              possibleViews[0]
              :
                <CButtonGroup className="p-3" label="Language switch" size="sm">
                  <CDropdown>
                    <CDropdownToggle href="#" color="primary">
                      {currentView}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {Object.entries(possibleViews).map(keyValuePair =>
                        <CDropdownItem
                          className="text-primary"
                          key={keyValuePair[0]}
                          value={keyValuePair[0]}
                          onClick={changeView}
                        >
                          {keyValuePair[1]}
                        </CDropdownItem>)
                      }
                    </CDropdownMenu>
                  </CDropdown>
                </CButtonGroup>

          }

          <CButtonGroup className="p-3" label="Language switch" size="sm">
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="language-switch"
              id="btn-en-language"
              autoComplete="off"
              label="EN"
              onClick={() => changeLanguage('en')}
            />
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="language-switch"
              id="btn-pt-language"
              autoComplete="off"
              label="PT"
              onClick={() => changeLanguage('pt')}
            />
          </CButtonGroup>

          <CButtonGroup  className="p-3" aria-label="Theme switch" size="sm">
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === 'default'}
              onChange={() => dispatch({ type: 'set', theme: 'light' })}
            />
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === 'dark'}
              onChange={() => dispatch({ type: 'set', theme: 'dark' })}
            />
          </CButtonGroup>

          { currentView === 'admin' ? 
            <CButtonGroup  
              disable="true"
              className="p-3"
              size="sm"
            >
              { listLogs !== undefined && listLogs !== null && listLogs.length !== 0 ?
                <CButton
                  color="primary"
                  disable="true"
                  className="position-relative"
                  onClick={() => history.push("/alerts")}
                >
                  {/*cilBellExclamation*/}
                  <CIcon icon={cilBell} />
                  <CBadge color="danger" position="top-end" shape="rounded-pill">
                    {listLogs.length === page_size ? page_size + "+" : listLogs.length}
                    <span className="visually-hidden">unread messages</span>
                  </CBadge>
                </CButton>
                : null  
              }
            </CButtonGroup>
            : currentView === 'owner' ?
            <CButtonGroup  
              disable="true"
              className="p-3"
              size="sm"
            >
                <CButton
                  color="primary"
                  disable="true"
                  className="position-relative"
                  onClick={() => history.push("/boxes")}
                >
                  {/*cilCalendarCheck*/}
                  <CIcon icon={cilCalendar} />
                  <CBadge color="danger" position="top-end" shape="rounded-pill">
                    <span className="visually-hidden">{translate('scheduling.to_be_done')}</span>
                  </CBadge>
                </CButton>
              </CButtonGroup>
            : null
          }
        </CHeaderNav>

        <CHeaderNav>
          <AppHeaderDropdown />
        </CHeaderNav>


      </CContainer>
    </CHeader>
  )
}

export default AppHeader
