import API_Calls_Generic from "./API_Calls_Generic";
export default function API_Calls_Users(setShowingSessionExperiedModal, handleLogout, addAlert){

    /*
        These functions have the meaning to generalise the calls to the api, allowing for code reuse
        Currently they receive the token, a Function to process the data, if necessary, additional information
        to give to the processing function, if necessary, and a setObject, to specify the set of the values
    */

    //Get Users
    function getUsers(token, jsonParameters, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            }
        };
        
        let url = "/api/users" + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "list_users",
            setObject,
            "Listed the Users sucessfully",
            file,
            screen);
    }

    //Get Single User
    function getSingleUser(token, jsonParameters, userId, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            }
        };
        
        let url = "/api/users/" + userId + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "current_user",
            setObject,
            "Listed User " + userId + " sucessfully",
            "User " + userId + " - " + file,
            screen);
    }

    //Create new User
    function postCreateUser(token, jsonBody, jsonParameters, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            },
            body : jsonBody
        };
        
        let jsonBodyParsed = JSON.parse(jsonBody);
        
        let url = "/api/users" + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericPostCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "new_user",
            setObject,
            "Created user " + jsonBodyParsed["name"] + " sucessfully",
            "User " + jsonBodyParsed["name"] + " - " + file,
            screen
        );
    }

    //Update a User
    function postUpdateUser(token, jsonBody, jsonParameters, userId, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            },
            body : jsonBody
        };
        
        let url = "/api/users/" + userId + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericPostCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "updated_user",
            setObject,
            "Updated user " + userId + " sucessfully",
            "User " + userId + " - " + file,
            screen
        );
    }

    //Get User alerts
    function getUserAlerts(token, jsonParameters, userId, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            }
        };
        
        let url = "/api/users/" + userId + "/alerts" + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "current_user_alerts",
            setObject,
            "Listed User " + userId + " alerts sucessfully",
            "User " + userId + " - " + file,
            screen);
    }

    //Get User alerts
    function getRoleTypes(token, jsonParameters, Processing_function, additional_information, setObject, file, screen) {
        
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            }
        };
        
        let url = "/api/users/role-types" + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "role_types",
            setObject,
            "Listed role types sucessfully",
            file,
            screen
        );
    }

    //Update a User password
    function postUpdateUserPassword(token, jsonBody, jsonParameters, userId, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
            	'Accept': 'application/json',
                'Authentication': token
            },
            body : jsonBody
        };
        
        let url = "/api/users/" + userId + "/change-password" + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).genericPostCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "updated_user_password",
            setObject,
            "Updated user " + userId + " password sucessfully",
            "User " + userId + " - " + file,
            screen
        );
    }

    //Login a user
    function loginUser(jsonBody, jsonParameters, Processing_function, additional_information, setObject, file, screen) {
  
        const requestOptions = {
            method: 'POST',
            //mode: 'cors',
            headers: { 
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': ' *',
                //"Access-Control-Allow-Headers": "*"
                //'Access-Control-Allow-Credentials': 'true',
            	'Accept': 'application/json'
            },
            body : jsonBody
        };
        
        let jsonBodyParsed = JSON.parse(jsonBody);
        
        let url = "/api/users/login" + API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).jsonVariablesToQuery(jsonParameters);
  
        API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert).loginCall(
            requestOptions,
            url,
            Processing_function,
            additional_information,
            "logged_user",
            setObject,
            "Logged in user sucessfully",
            "User " + jsonBodyParsed["username"] + " - " + file,
            screen
        );
    }
    

    return {
        getUsers,
        getSingleUser,
        postCreateUser,
        postUpdateUser,
        getUserAlerts,
        getRoleTypes,
        postUpdateUserPassword,
        loginUser
    };

}
