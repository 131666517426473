import React, {Suspense} from 'react'
import {Router} from 'react-router-dom'
import {CSpinner} from '@coreui/react-pro'
import './scss/style.scss'
import {AuthProvider} from "./context/AuthContext";
import history from './history';
import Routes from './Routes';

function App() {
  return (
      <Suspense fallback={<CSpinner color="primary" />}>

        <AuthProvider>
          <Router history={history}>
            <Routes/>
          </Router>
        </AuthProvider>
      </Suspense>
  )
}export default App;
