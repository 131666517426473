
export default function API_Calls_Generic(setShowingSessionExperiedModal, handleLogout, addAlert){


    function filtersAndSortsToJsonParameters(listOfFilters, sortDefined){
        let jsonParameters = {};
        
        if (listOfFilters !== undefined){
            for (let filter in listOfFilters){
                if (listOfFilters[filter] === ""){
                    continue;
                }
                jsonParameters[filter] = listOfFilters[filter];
            }
        }
        
        if(sortDefined !== undefined && sortDefined["sortOption"] !== "none"){
            jsonParameters["sort"] = sortDefined["order"] + sortDefined["sortOption"];
        }

        return jsonParameters;
    }
    

    function jsonVariablesToQuery(jsonParameters) {
        let parameters = [];
        for (let key in jsonParameters) {
            let encodedKey = encodeURIComponent(key);
            let encodedObject = encodeURIComponent(jsonParameters[key])
            parameters.push(encodedKey + '=' + encodedObject);
        }

        if (parameters.length === 0){
            return ""
        }
        else{
            return "?" + parameters.join('&');
        }
    }

    
    //Generic API Call
    function genericCall(
        requestOptions,
        url,
        Processing_function,
        additional_information,
        new_variable,
        setObject,
        sucessfullDetailedMessage,
        action,
        screen
    ){

        let currentUserIdText = "User undefined";
        if (localStorage === undefined || localStorage.getItem("id") === null || localStorage.getItem("id") === undefined){
            currentUserIdText = "User not logged in";
        }
        else{
            currentUserIdText = "User " + localStorage.getItem("id")
        }
        screen = currentUserIdText + " - " + screen;

        if (!sucessfullDetailedMessage || sucessfullDetailedMessage === null){
            console.log("genericCall missing sucessfullDetailedMessage parameter");
            console.log(url);
        }
        if (!action || action === null){
            console.log("genericCall missing action parameter");
            console.log(url);
        }
        if (!screen || screen === null){
            console.log("genericCall missing screen parameter");
            console.log(url);
        }

        if (!additional_information || additional_information === null){
            additional_information = {}
        }

        fetch(url, requestOptions).then((response) => {
            if (response.status === 200) {
                response.text().then((response_json_text) => {
                    let values = JSON.parse(response_json_text);

                    if (setObject !== null && setObject !== "" && setObject !== undefined){
                        setObject(values);
                    }
                    additional_information[new_variable] = values;
                    if (Processing_function !== null && Processing_function !== ""){
                        Processing_function(additional_information);
                    }

                    addAlert(sucessfullDetailedMessage, "success", action, screen, false, true);
                });
            }
            else if (response.status === 401) {
                response.text().then(() => {
                    if (setShowingSessionExperiedModal !== undefined && setShowingSessionExperiedModal !== null && setShowingSessionExperiedModal !== ""){
                        //setShowingSessionExperiedModal(true);
                        handleLogout();
                    }
                    else{
                        addAlert("Unable To logout", "danger", action, screen, true, true);
                    }
                    addAlert("Session Expired", "warning", action, screen, false, true);
                });
            }
            else if (response.status === 403) {
                response.text().then(() => {
                    addAlert("You don't have a role to get this information", "warning", action, screen, true, true);
                });
            }else {
                response.text().then((response_json_text) => {
                    let values = JSON.parse(response_json_text);
                    addAlert(response.status.toString().concat(" ").concat(values.message), "danger", action, screen, true, true);
                });
            }
        })
        .catch(error => {
            addAlert(error, "danger", action, screen, true, true);
        }
        );

    }

    function genericGetCall(requestOptions, url, Processing_function, additional_information, new_variable, setObject){
        genericCall(requestOptions, url, Processing_function, additional_information, new_variable, setObject);
    }

    async function asyncGenericCall(
        requestOptions,
        url,
        setObject,
        sucessfullDetailedMessage,
        action,
        screen
    ){

        let currentUserIdText = "User undefined";
        if (localStorage === undefined || localStorage.getItem("id") === null || localStorage.getItem("id") === undefined){
            currentUserIdText = "User not logged in";
        }
        else{
            currentUserIdText = "User " + localStorage.getItem("id")
        }
        screen = currentUserIdText + " - " + screen;

        if (!sucessfullDetailedMessage || sucessfullDetailedMessage === null){
            console.log("genericCall missing sucessfullDetailedMessage parameter");
            console.log(url);
        }
        if (!action || action === null){
            console.log("genericCall missing action parameter");
            console.log(url);
        }
        if (!screen || screen === null){
            console.log("genericCall missing screen parameter");
            console.log(url);
        }

        const response = await fetch(url, requestOptions);
        const response_json_text = await response.text();
        const response_json = JSON.parse(response_json_text);
        const response_text = response_json["message"];

        if (response.ok){

            if (setObject !== null && setObject !== "" && setObject !== undefined){
                setObject(response_json);
            }
            addAlert(sucessfullDetailedMessage, "success", action, screen, false, true);

            return {
                "status" : 200,
                "message" : response_json
            };
        }
        else if (response.status === 401){
            if (setShowingSessionExperiedModal !== undefined && setShowingSessionExperiedModal !== null && setShowingSessionExperiedModal !== ""){
                //setShowingSessionExperiedModal(true);
                handleLogout();
            }
            else{
                addAlert("Unable To logout", "danger", action, screen, true, true);
            }
            addAlert("Session Expired", "warning", action, screen, false, true);
        }
        else if (response.status === 403) {
            addAlert("You don't have a role to make this request", "warning", action, screen, true, true);
        }
        else{
            addAlert(response.status.toString().concat(" ").concat(response_text), "danger", action, screen, true, true);
        }

        return {
            "status" : response.status.toString(),
            "message" : response_text
        };
    }


    //Generic API Call
    function genericPostCall(
        requestOptions,
        url,
        Processing_function,
        additional_information,
        new_variable,
        setObject,
        sucessfullDetailedMessage,
        action,
        screen
    ){

        let currentUserIdText = "User undefined";
        if (localStorage === undefined || localStorage.getItem("id") === null || localStorage.getItem("id") === undefined){
            currentUserIdText = "User not logged in";
        }
        else{
            currentUserIdText = "User " + localStorage.getItem("id")
        }
        screen = currentUserIdText + " - " + screen;

        
        if (!additional_information || additional_information === null){

            if (!sucessfullDetailedMessage || sucessfullDetailedMessage === null){
                console.log("genericCall missing sucessfullDetailedMessage parameter");
                console.log(url);
            }
            if (!action || action === null){
                console.log("genericCall missing action parameter");
                console.log(url);
            }
            if (!screen || screen === null){
                console.log("genericCall missing screen parameter");
                console.log(url);
            }
    
            if (!additional_information || additional_information === null){
                additional_information = {}
            }
    
            fetch(url, requestOptions).then((response) => {
                if (response.status === 200) {
                    response.text().then((response_json_text) => {
                        let response_json = JSON.parse(response_json_text);
                        // let response_text = response_json["message"];
    
                        if (setObject !== null && setObject !== "" && setObject !== undefined){
                            setObject(response_json);
                        }
                        additional_information[new_variable] = response_json;
                        if (Processing_function !== null && Processing_function !== ""){
                            Processing_function(additional_information);
                        }
                        addAlert(sucessfullDetailedMessage, "success", action, screen, true, true);
                    });
                }
                else if (response.status === 401) {
                    response.text().then(() => {
                        if (setShowingSessionExperiedModal !== undefined && setShowingSessionExperiedModal !== null && setShowingSessionExperiedModal !== ""){
                            //setShowingSessionExperiedModal(true);
                            handleLogout();
                        }
                        else{
                            addAlert("Unable To logout", "danger", action, screen, true, true);
                        }
                        addAlert("Session Expired", "warning", action, screen, false, true);
                    });
                }
                else if (response.status === 403) {
                    response.text().then(() => {
                        addAlert("You don't have a role to make this request", "warning", action, screen, true, true);
                    });
                }else {
                    response.text().then((response_json_text) => {
                        let response_json = JSON.parse(response_json_text);
                        let response_text = response_json["message"];
                        addAlert(response.status.toString().concat(" ").concat(response_text), "danger", action, screen, true, true);
                    });
                }
            })
            .catch(error => {
                addAlert(error, "danger", action, screen, true, true);
            }
            );
        }
    }
    
    async function asyncGenericPostCall(
        requestOptions,
        url,
        Processing_function,
        additional_information,
        new_variable,
        setObject,
        sucessfullDetailedMessage,
        action,
        screen
    ){

        let currentUserIdText = "User undefined";
        if (localStorage === undefined || localStorage.getItem("id") === null || localStorage.getItem("id") === undefined){
            currentUserIdText = "User not logged in";
        }
        else{
            currentUserIdText = "User " + localStorage.getItem("id")
        }
        screen = currentUserIdText + " - " + screen;

        if (!sucessfullDetailedMessage || sucessfullDetailedMessage === null){
            console.log("genericCall missing sucessfullDetailedMessage parameter");
            console.log(url);
        }
        if (!action || action === null){
            console.log("genericCall missing action parameter");
            console.log(url);
        }
        if (!screen || screen === null){
            console.log("genericCall missing screen parameter");
            console.log(url);
        }

        if (!additional_information || additional_information === null){
            additional_information = {}
        }

        const response = await fetch(url, requestOptions);
        let response_json_text = await response.text();
        let response_json = JSON.parse(response_json_text);
        let response_text = response_json["message"];

        if (response.ok){

            if (setObject !== null && setObject !== "" && setObject !== undefined){
                setObject(response_json);
            }
            /*
            additional_information[new_variable] = values;
            if (Processing_function !== null && Processing_function !== ""){
                Processing_function(additional_information);
            }
            */
            addAlert(sucessfullDetailedMessage, "success", action, screen, true, true);

            return {
                "status" : 200,
                "message" : response_json
            };
        }
        else if (response.status === 401){
            if (setShowingSessionExperiedModal !== undefined && setShowingSessionExperiedModal !== null && setShowingSessionExperiedModal !== ""){
                //setShowingSessionExperiedModal(true);
                handleLogout();
            }
            else{
                addAlert("Unable To logout", "danger", action, screen, true, true);
            }
            addAlert("Session Expired", "warning", action, screen, false, true);
        }
        else if (response.status === 403) {
            addAlert("You don't have a role to make this request", "warning", action, screen, true, true);
        }
        else{
            if (response_text === undefined){
                addAlert(response.status.toString().concat(" ").concat(response_text), "danger", action, screen, true, true);
            }
            else{
                addAlert(response.status.toString().concat(" ").concat(response.statusText), "danger", action, screen, true, true);
            }
        }

        return {
            "status" : response.status.toString(),
            "message" : response_text
        };
    }


    function loginCall(
        requestOptions,
        url,
        Processing_function,
        additional_information,
        new_variable,
        setObject,
        sucessfullDetailedMessage,
        action,
        screen
    ){

        if (!sucessfullDetailedMessage || sucessfullDetailedMessage === null){
            console.log("genericCall missing sucessfullDetailedMessage parameter");
            console.log(url);
        }
        if (!action || action === null){
            console.log("genericCall missing action parameter");
            console.log(url);
        }
        if (!screen || screen === null){
            console.log("genericCall missing screen parameter");
            console.log(url);
        }

        if (!additional_information || additional_information === null){
            additional_information = {}
        }

        fetch(url, requestOptions).then((response) => {
            if (response.status === 200) {
                response.text().then((response_json_text) => {
                    let response_json = JSON.parse(response_json_text);

                    if (setObject !== null && setObject !== "" && setObject !== undefined){
                        setObject(response_json);
                    }
                    additional_information[new_variable] = response_json;
                    if (Processing_function !== null && Processing_function !== ""){
                        Processing_function(additional_information);
                    }
                    addAlert(sucessfullDetailedMessage, "success", action, screen, true, true);
                });
            }
            else if (response.status === 401) {
                response.text().then(() => {
                    addAlert("Login Failed - Credentials incorrect", "warning", action, screen, true, true);
                });
            }
            else if (response.status === 422) {
                response.text().then(() => {
                    addAlert("Format of credentials incorret. Please recheck all fields.", "warning", action, screen, true, true);
                });
            }else {
                response.text().then((response_json_text) => {
                    let response_json = JSON.parse(response_json_text);
                    let response_text = response_json["message"];
                    addAlert(response.status.toString().concat(" ").concat(response_text), "danger", action, screen, true, true);
                });
            }
        })
        .catch(error => {
            addAlert(error, "danger", action, screen, true, true);
        }
        );

    }

    return {
        filtersAndSortsToJsonParameters,
        genericCall,
        genericGetCall,
        asyncGenericCall,
        genericPostCall,
        asyncGenericPostCall,
        jsonVariablesToQuery,
        loginCall
    };
}
