import { useEffect, useState} from 'react';
import history from '../../history';
import API_Calls_Users from '../../utils/api/API_Calls_Users'

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [alertsList, setAlertsList] = useState([]);
  const [showingSessionExperiedModal, setShowingSessionExperiedModal] = useState(false);

  useEffect(() => {
// every time a window is closed the token in localstorage will be deleted
// downside - if two windows are open and one is closed then the token will be deleted
// thus the last window open won't have the token and the client will be redirect to sign in page
//window.addEventListener("beforeunload", deleteToken);

// sessionStorage is the same as window Storage, two different windows will have different storages
// this can be use if we want two or more different clients in the same browser or not letting the same client
// in two or more different tabs open with his account, makes almost the same function as the
// window.addEventListener("beforeunload", deleteToken), the difference is that in window.addEventListener "beforeunload"
// if the client closes any window then he will be automatically logged out (token removed)
//const token = sessionStorage.getItem('token');

    const token = localStorage.getItem('token');
    const roles = JSON.parse(localStorage.getItem('roles'));
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');
    if (token && roles && id && name) {
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);

  // This function adds an alert to alertsList and sends a POST request to createLogs to store the alert in logs
  function addAlert(msg, color, msg_ref, source_ref, showClient=true, postServer=true) {
    if (showClient === true){
      addAlertUserSide(msg, color);
    }
    if (postServer === true){
      addAlertServerSide(msg, color, msg_ref, source_ref);
    }
  }

  function addAlertUserSide(msg, color){
    // Used to force an update on alertsList. We create new values instead of referencing alertsList items.
    let list = [];

    // TODO: Currently this is working, however, it probably has a race condition and may not show some alerts
    for (let i in alertsList) {
      list.push(alertsList[i]);
    }

    list.push(
      {
        "message": msg,
        "color": color,
        "visible": true,
      }
    );

    // update alertsList
    
    alertsList.push({
      "message": msg,
      "color": color,
      "visible": true,
    })

    setAlertsList(list);
    window.scrollTo(0, 0);
  }

  function addAlertServerSide(msg, color, msg_ref, source_ref) {

    let url = "/api/logs";

    let requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authentication": localStorage.getItem('token')
      },
      body: JSON.stringify([{
        "date": (new Date()).toISOString(),
        "id-source": 3,
        "msg": msg,
        "msg-ref": msg_ref,
        "source-ref": source_ref
      }])
    }


    // POST request to createLogs and ignore the response
    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
      }
      else if (response.status === 401) {
          response.json().then((values) => {
              if (setShowingSessionExperiedModal !== undefined && setShowingSessionExperiedModal !== null && setShowingSessionExperiedModal !== ""){
                //setShowingSessionExperiedModal(true);
                handleLogout();
              }
              else{
                addAlertUserSide("Unable To logout", "danger");
              }
              //addAlertUserSide("Session Expired", "warning");
          });
      }
      else {
        addAlertUserSide(response.status.toString().concat(" ").concat(response.statusText), "danger");
      }
    })
    .catch(error => {
      addAlertUserSide(error, color);
    });
  
  }

  function deleteInfoInLocalStorage(){
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('roles');
    localStorage.removeItem('name');
  }

  function handleUserInfo(){
    const token = localStorage.getItem('token');
    const roles = JSON.parse(localStorage.getItem('roles'));
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');
    const element = {"token":token,"roles":roles,"id":id,"name":name};
    setUserInfo(element);
  }

  async function handleLogin(values) {
    //console.log("values", values);
    const password = values.password;
    const email = values.email;
    let jsonBody = JSON.stringify({ "password": password, "username": email })

    setAuthenticated(false);
    deleteInfoInLocalStorage();
    API_Calls_Users("", handleLogout, addAlert).loginUser(jsonBody, "", posLogin, "", "", "Logging in to the platform", "Login");
  }

  function posLogin(additional_information){
    localStorage.setItem('token', additional_information["logged_user"].token);
    localStorage.setItem('id', additional_information["logged_user"].user.id);

    let possibleViewsArray = [];
    let userRolesArray = additional_information["logged_user"].user.roles;

    for (let i in userRolesArray){
      if (userRolesArray[i] === "client" || userRolesArray[i] === "owner"){
        possibleViewsArray.push(userRolesArray[i]);
      }
      else if (userRolesArray[i].toLowerCase().includes("admin") && !possibleViewsArray.includes("admin")){
        possibleViewsArray.push("admin");
      }
    }

    if (possibleViewsArray.length === 0){
      possibleViewsArray.push("guest");
    }

    console.log(possibleViewsArray);

    localStorage.setItem('roles', JSON.stringify(userRolesArray));
    localStorage.setItem('possibleViews', possibleViewsArray);
    localStorage.setItem('currentView', possibleViewsArray[0]);
    localStorage.setItem('name', additional_information["logged_user"].user.name);
    setAuthenticated(true);
    setShowingSessionExperiedModal(false);
    history.push("/");
  }

  function handleLogout() {
    deleteInfoInLocalStorage();
    setAuthenticated(false);
    history.push("/");
  }

  function isAuthenticated() {

    const token = localStorage.getItem('token');
    const roles = JSON.parse(localStorage.getItem('roles'));
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');
    if (token && roles && id && name) {
      setAuthenticated(true);
    }
    else{
      handleLogout();
    }
  }

  return { authenticated, loading, handleLogin, handleLogout, showingSessionExperiedModal, setShowingSessionExperiedModal, userInfo , handleUserInfo ,setLoading,deleteInfoInLocalStorage, isAuthenticated, addAlert, alertsList, setAlertsList };
}
