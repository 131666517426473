import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro'

import { AppSidebarNav } from './AppSidebarNav'

import logoFullLight from '../assets/images/wexcedo_logo_full_light_mode.png'
import logoSmallLight from '../assets/images/wexcedo_logo_small_light_mode.png'

import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import {CImage} from "@coreui/react";

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const [ currentView ] = useState(
    localStorage.getItem("currentView") !== null && localStorage.getItem("currentView") !== undefined ?
      localStorage.getItem("currentView") : "guest"
  );

  useTranslation()
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      style={
        currentView === "admin" ?
        {background:"#3c4b64"}
        : currentView === "owner" ?
        {background:"#711fcf"}
        : currentView === "client" ?
        {background:"#cf5f1f"}
        : currentView === "guest" ?
        {background:"#1f97cf"}
        : {background:"#1f97cf"}}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="m-0 p-0" to="/">
        {/*TODO: (Non-priority) Missing dark mode */}
        <CImage className="sidebar-brand-full" src={logoFullLight} height={45}/>
        <CImage className="sidebar-brand-narrow" src={logoSmallLight} height={45}/>
        
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation()._nav} />
        </SimpleBar>
      </CSidebarNav>

      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />

    </CSidebar>
  )
}

export default React.memo(AppSidebar)
