import React from 'react'
import { useLocation } from 'react-router-dom'

import routes_breadcrumbs from '../Routes_breadcrumbs'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro'

import history from '../history';

const AppBreadcrumb = () => {

  const location = useLocation();
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)


    /*
      [by: Carlos]
      Don't change this, if user calls an invalid URL then he will be redirect to Dashboard
    */
    if(currentRoute === undefined)
      return 'Dashboard'
    else
      return currentRoute.name
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split(/\/|-/).reduce((prev, curr, index, array) => {
      const currentPathnamePre = `${prev}/${curr}`
      const currentPathname = "/" + currentPathnamePre.split('/').slice(1).join('-') // Change "/" to "-" after the first "/"
      breadcrumbs.push({
        pathname: currentPathname,
        name: getRouteName(currentPathname, routes_breadcrumbs),
        active: index + 1 === array.length ? true : false,
      })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  //define css mouse over style to apply on breadcrumb items
  const mouseOverStyle = {
    cursor: 'pointer',
  };

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem className="text-primary" style={mouseOverStyle} onClick={()=>{history.push("/")}}><u>Home</u></CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {
              /*if its the last item of the breadcrumb, set active property. If not, add a link to the item.*/
              ...(breadcrumb.active ? { active: true } :
              { onClick: ()=>{
                  history.push({
                    "pathname": breadcrumb.pathname,
                    "state": [location.state[0]]
                  })
                },
                className: "text-primary",
                style: mouseOverStyle}

              )
            }
            key={index}
          >
            {
              /*if its the last item of the breadcrumb, do not underline. If its another item underlinte it.*/
              breadcrumb.active? breadcrumb.name:
              <u>{breadcrumb.name}</u>
            }
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )

}

export default React.memo(AppBreadcrumb)
